

.nav-default {
    color: #747474;
	font-size: 16px;
	margin-right: 30px;
	font-family: "DMSans-Medium";
    padding-bottom: 3px;
}
.nav-active {
	color: #0a0a0a;
    position: relative;
}

.nav-active::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    width: 30px;
    height: 3px;
    background-color: #000;
    margin-left: auto;
    margin-right: auto;
    border-radius: 30px;

  }



.menu-default {
    color: #747474;
	font-size: 16px;
	margin-right: 30px;
	font-family: "DMSans-Medium";
    padding-bottom: 3px;
    width: max-content;
    margin-bottom: 20px;
}
.menu-active {
	color: #0a0a0a;
    position: relative;
}

.menu-active::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    width: 30px;
    height: 3px;
    background-color: #000;
    margin-left: auto;
    margin-right: auto;
    border-radius: 30px;

  }